import React, { Component } from 'react'
import { css } from 'emotion'
import { CFLink, CFView, Space, Text } from 'components'
import {
  appStore,
  googlePlay,
  locationMap,
  facebook,
  yelp,
  zomato,
} from 'images'
import { MEDIA_QUERY } from 'styles'

class Contact extends Component {
  render() {
    const { HoursElement, ContactUsElement } = this.props
    return (
      <div className={styles.container}>
        <CFLink href="https://goo.gl/maps/Dji39EHdydhjLnFPA">
          <img
            src={locationMap}
            alt="James on Hastings Map"
            className={styles.locationMap}
          />
        </CFLink>
        <div className={styles.innerContainer}>
          <div className={styles.column}>
            <Text heading h2 bold color="#957B60">
              DOWNLOAD APP
            </Text>
            <Space h2 />
            <CFView w="30rem" mb="1rem">
              <Text h6 white>
                James On Hastings is now taking online orders. Order through our
                website or download our app.
              </Text>
            </CFView>
            <Space h1 />
            <CFView row>
              <div className={styles.buttonBar}>
                <CFLink href="https://apps.apple.com/us/app/james-on-hastings/id1487387899">
                  <img
                    className={styles.mobile}
                    src={appStore}
                    alt="App Store"
                  />
                </CFLink>
                <Space w2 />
                <CFLink href="https://play.google.com/store/apps/details?id=tech.codefusion.jamesonhastings">
                  <img
                    className={styles.mobile}
                    src={googlePlay}
                    alt="Goodle Play"
                  />
                </CFLink>
              </div>
            </CFView>
            <Space h3 />
            <Text heading h2 bold color="#957B60">
              SOCIAL MEDIA
            </Text>
            <Space h2 />
            <div className={styles.buttonBar}>
              <CFLink href="https://www.facebook.com/James-On-Hastings-236561243083280/">
                <img
                  className={styles.icon}
                  src={facebook}
                  alt="James on Hastings Facebook"
                />
              </CFLink>
              <Space w2 />
              <CFLink href="https://www.yelp.ca/biz/james-on-hastings-chinese-restaurant-vancouver">
                <img
                  className={styles.icon}
                  src={yelp}
                  alt="James on Hastings Yelp"
                />
              </CFLink>
              <Space w2 />
              <CFLink href="https://www.zomato.com/vancouver/james-on-hastings-hastings-sunrise-vancouver">
                <img
                  className={styles.icon}
                  src={zomato}
                  alt="James on Hastings Zomato"
                />
              </CFLink>
            </div>
          </div>
          <Space w2 h2 />
          <div className={styles.column}>
            <Text heading h2 bold color="#957B60">
              BUSINESS HOURS
            </Text>
            <Space height="2.2rem" />
            <div className={styles.hours}>{HoursElement}</div>
          </div>
          <div className={styles.column}>
            <Text heading h2 bold color="#957B60">
              CONTACT US
            </Text>
            <Space h2 />
            {ContactUsElement}
          </div>
        </div>
      </div>
    )
  }
}

const styles = {
  container: css({
    width: '100%',
    maxWidth: '130rem',
    paddingTop: '0.5rem',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    [MEDIA_QUERY.LG]: {
      flexDirection: 'column',
      alignItems: 'center',
      padding: 0,
      textAlign: 'center',
    },
  }),
  innerContainer: css({
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    padding: '4rem',
    [MEDIA_QUERY.LG]: {
      padding: '2rem 0 4rem 0',
      width: '100%',
      flexDirection: 'column',
      alignItems: 'center',
      textAlign: 'center',
    },
  }),
  locationMap: css({
    width: '100%',
    objectFit: 'contain',
  }),
  hours: css({
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  }),
  mobile: css({
    height: '3.5rem',
    objectFit: 'contain',
    cursor: 'pointer',
    transition: 'all .2s',
    '&:hover': {
      transform: 'scale(1.03)',
    },
  }),
  column: css({
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    maxWidth: '35rem',
    [MEDIA_QUERY.SM]: {
      alignItems: 'center',
      marginTop: '2rem',
      width: 'auto',
    },
  }),
  buttonBar: css({
    display: 'flex',
    [MEDIA_QUERY.SM]: {
      justifyContent: 'center',
    },
  }),
  icon: css({
    height: '3.5rem',
    width: '3.5rem',
    cursor: 'pointer',
    transition: 'all .2s',
    '&:hover': {
      transform: 'scale(1.1)',
    },
  }),
}

export default Contact
