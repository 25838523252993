import React, { Component } from 'react'
import { css } from 'emotion'
import { CFLink, CFView } from 'components'
import HomeHeader from './HomeHeader'
import { hero, downloadButton } from 'images'
import { MEDIA_QUERY } from 'styles'

class Hero extends Component {
  render() {
    const { OrderNowButtonElement } = this.props
    return (
      <div className={styles.container}>
        <HomeHeader />
        <div className={styles.heroTextContainer}>
          <CFView className={styles.buttons}>
            {OrderNowButtonElement}
            <CFLink href="http://onelink.to/q62pr2">
              <img
                src={downloadButton}
                alt="Download App"
                className={styles.downloadButton}
              />
            </CFLink>
          </CFView>
        </div>
      </div>
    )
  }
}
const styles = {
  container: css({
    height: '100vh',
    width: '100%',
    background: `url(${hero}) no-repeat`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    position: 'relative',
    boxShadow: '1px 3px 5px rgba(0,0,0,.7)',
    [MEDIA_QUERY.MD]: {
      height: '100vh',
      backgroundPosition: '40%',
    },
  }),
  heroText: css({
    width: '100%',
  }),
  buttons: css({
    width: '45%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    bottom: '5%',
    right: '1%',
    [MEDIA_QUERY.MD]: {
      flexDirection: 'column',
      right: 'auto',
      width: '100%',
    },
  }),
  downloadButton: css({
    width: '100%',
    transition: 'all .2s',
    '&:hover': {
      transform: 'scale(1.03)',
    },
  }),
}

export default Hero
