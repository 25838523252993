import React, { Component } from 'react'
import { css } from 'emotion'
import { NavbarView } from 'components'
import { MEDIA_QUERY } from 'styles'
import { LocationSelect, Logo } from 'cf-web-app/components'

export default class PageHeader extends Component {
  render() {
    return (
      <div className={styles.container}>
        <div className={styles.innerContainer}>
          <div className={styles.location}>
            <div className={styles.logo}>
              <Logo />
            </div>
            <LocationSelect />
          </div>
          <NavbarView />
        </div>
      </div>
    )
  }
}

const styles = {
  container: css({
    width: '100%',
    height: '7rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 99,
    [MEDIA_QUERY.SM]: {
      height: '13rem',
    },
  }),
  innerContainer: css({
    position: 'relative',
    width: '90%',
    height: '100%',
    maxWidth: '130rem',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    [MEDIA_QUERY.SM]: {
      flexDirection: 'column',
    },
  }),
  logo: css({
    position: 'absolute',
    top: '0.2rem',
    left: '-2rem',
    [MEDIA_QUERY.SM]: {
      position: 'static',
    },
  }),
  location: css({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    [MEDIA_QUERY.SM]: {
      flexDirection: 'column',
    },
  }),
}
