import React from 'react'
import { Container, Space } from 'components'
import { css } from 'emotion'
import { MEDIA_QUERY } from 'styles'

const CartView = ({ RewardsElement, SummaryReceiptElement }) => (
  <Container>
    <div className={styles.content}>
      <div className={styles.border}>
        {RewardsElement}
        <Space width="4rem" height="4rem" />
        {SummaryReceiptElement}
      </div>
    </div>
  </Container>
)

const styles = {
  content: css({
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    lineHeight: 1.5,
  }),
  border: css({
    display: 'flex',
    alignItems: 'center',
    width: '90%',
    maxWidth: '120rem',
    padding: '2rem 4rem 2rem 4rem',
    marginTop: '2rem',
    marginBottom: '2rem',
    backgroundColor: 'rgba(255,255,255,.7)',
    borderRadius: '1rem',
    minHeight: 'calc(100vh - 10rem)',
    [MEDIA_QUERY.SM]: {
      flexDirection: 'column-reverse',
      padding: '2rem',
      minHeight: 'calc(100vh - 15rem)',
    },
  }),
}

export default CartView
