import React from 'react'
import { MediaQuery } from 'components'
import { css } from 'emotion'
import { MEDIA_QUERY } from 'styles'

const CategoriesTopContainerView = ({
  CategoryListMDElement,
  CategoryListElement,
  ProductListMDElement,
  ProductListElement,
}) => (
  <div className={styles.menu}>
    <div className={styles.border}>
      <div className={styles.categories}>
        <MediaQuery maxWidth={767}>
          {matches => {
            if (matches) {
              return CategoryListMDElement
            }
            return CategoryListElement
          }}
        </MediaQuery>
      </div>
      <MediaQuery maxWidth={767}>
        {matches => {
          if (matches) {
            return ProductListMDElement
          }
          return ProductListElement
        }}
      </MediaQuery>
    </div>
  </div>
)

const styles = {
  menu: css({
    height: 'calc(100vh - 7rem)',
    display: 'flex',
    justifyContent: 'center',
    [MEDIA_QUERY.SM]: {
      height: 'calc(100vh - 13rem)',
      paddingTop: '2rem',
      paddingBottom: '2rem',
    },
  }),
  border: css({
    width: '90%',
    maxWidth: '120rem',
    display: 'flex',
    padding: '2rem',
    marginTop: '2rem',
    marginBottom: '2rem',
    backgroundColor: 'rgba(255,255,255,.7)',
    borderRadius: '1rem',
    [MEDIA_QUERY.MD]: {
      padding: 0,
      paddingTop: '1rem',
      height: '100%',
      flexDirection: 'column',
      margin: 0,
      overflowX: 'hidden',
      overflowY: 'scroll',
    },
  }),
  categories: css({
    display: 'flex',
    flexDirection: 'column',
  }),
}
export default CategoriesTopContainerView
