import React from 'react'
import { logo, logoTitle } from 'images'
import { css } from 'emotion'
import { MEDIA_QUERY } from 'styles'

const LogoView = ({ className }) => (
  <div className={css(styles.container, className)}>
    <img className={styles.logo} src={logo} alt="James on Hastings" />
    <img className={styles.logoTitle} src={logoTitle} alt="James on Hastings" />
  </div>
)

const styles = {
  container: css({
    display: 'flex',
    alignItems: 'flex-start',
    cursor: 'pointer',
    transition: 'all .2s',
    '&:hover': {
      transform: 'scale(1.03)',
    },
    [MEDIA_QUERY.SM]: {
      margin: 0,
      alignItems: 'center',
    },
  }),
  logo: css({
    objectFit: 'contain',
    height: '7.2rem',
    paddingRight: '1rem',
    [MEDIA_QUERY.SM]: {
      height: '7rem',
    },
  }),
  logoTitle: css({
    marginTop: '.5rem',
    objectFit: 'contain',
    height: '6rem',
    [MEDIA_QUERY.SM]: {
      marginTop: '0',
      marginLeft: '1rem',
      height: '4.5rem',
    },
  }),
}

export default LogoView
