import React from 'react'
import { css } from 'emotion'
import PageFooter from './PageFooter'
import Hero from './Hero'
import Promotions from './Promotions'
import Contact from './Contact'
import { homebg } from 'images'

class HomeView extends React.Component {
  render() {
    const {
      HoursElement,
      ContactUsElement,
      OrderNowButtonElement,
      LogoElement,
      locationId,
    } = this.props
    return (
      <section className={styles.container}>
        <Hero OrderNowButtonElement={OrderNowButtonElement} />
        <div className={styles.BGcontainer}>
          <Promotions locationId={locationId} />
          <Contact
            HoursElement={HoursElement}
            LogoElement={LogoElement}
            ContactUsElement={ContactUsElement}
            locationId={locationId}
          />
        </div>
        <PageFooter />
      </section>
    )
  }
}

const styles = {
  container: css({
    width: '100%',
  }),
  BGcontainer: css({
    width: '100%',
    background: `url(${homebg}) no-repeat`,
    backgroundSize: 'cover',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    boxShadow: '1px 3px 5px rgba(0,0,0,.7)',
  }),
}

export default HomeView
