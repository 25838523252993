import React from 'react'
import { css } from 'emotion'
import { CFLink, CFView, Text } from 'components'
import { MEDIA_QUERY } from 'styles'

export default ({ address, phoneNumber, email }) => (
  <CFView column>
    {address && (
      <CFView row w="100%" mb="1rem" className={styles.address}>
        <CFView w="9rem">
          <Text heading h4 white>
            Address
          </Text>
        </CFView>
        <CFLink href="https://goo.gl/maps/Dji39EHdydhjLnFPA">
          <Text heading h4 white>
            {address}
          </Text>
        </CFLink>
      </CFView>
    )}
    {phoneNumber && (
      <CFView row w="100%" mb="1rem">
        <CFView w="9rem">
          <Text heading h4 white>
            Phone
          </Text>
        </CFView>
        <Text heading h4 white>
          {phoneNumber}
        </Text>
      </CFView>
    )}
    {email && (
      <CFView row w="100%">
        <CFView w="9rem">
          <Text heading h4 white>
            Email
          </Text>
        </CFView>
        <Text heading h4 white>
          {email}
        </Text>
      </CFView>
    )}
  </CFView>
)

const styles = {
  address: css({
    textAlign: 'left',
  }),
}
