export * from 'cf-core/src/images'
export const appStore =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/ebisu/app-store.png'
export const cftitle =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/ebisu/cftitle.png'
export const googlePlay =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/ebisu/google-play.png'
export const user =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/ebisu/user.png'
export const orderStatusProgress =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1554602154/general/OrderStatusProgress.png'
export const orderStatusMeter =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1554666730/general/OrderStatusMeter.png'
export const promoHeader =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto/Shaolin/promoHeader.png'
export const restaurant =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1562129124/Shaolin/restaurant.jpg'
export const yelp =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto/Shaolin/yelp.png'
export const facebook =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto/Shaolin/facebook.png'
export const zomato =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto/Shaolin/zomato.png'
export const cflogo =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1570765843/hokepoke/cflogo.png'
export const gallery =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1570765847/hokepoke/gallery.png'
export const rewardbg =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1562130648/Shaolin/rewardbg.jpg'
export const pointsMeter =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto/Shaolin/pointsMeter.jpg'
export const rewardArrowLeft =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto/Shaolin/rewardArrowLeft.png'
export const rewardArrowRight =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto/Shaolin/rewardArrowRight.png'
export const redeemButton =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto/Shaolin/redeemButton.png'
export const rewardDefault =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto/Shaolin/rewardDefault.png'
export const logoSmall =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1570765846/hokepoke/logoSmall.jpg'
export const hero =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1573419209/jamesonhastings/hero.png'
export const downloadButton =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1573317473/jamesonhastings/downloadButton.png'
export const orderButton =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1573317473/jamesonhastings/orderButton.png'
export const navbg =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1573317473/jamesonhastings/navbg.png'
export const logo =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1573317473/jamesonhastings/logo.png'
// export const logoTitle =
//   'https://res.cloudinary.com/codefusiontech/image/upload/v1573317473/jamesonhastings/logoTitle.png'
export const background =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1573317473/jamesonhastings/background.jpg'
export const promotions =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1589672352/jamesonhastings/James_promotions.png'
export const foodGallery1 =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1573317473/jamesonhastings/foodGallery1.png'
export const foodGallery2 =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1573419135/jamesonhastings/foodGallery2.png'
export const bgwatermark =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1573317473/jamesonhastings/bgwatermark.png'
export const locationMap =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1573317473/jamesonhastings/locationMap.png'
export const homebg =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1573317473/jamesonhastings/homebg.jpg'

export { default as logoTitle } from './logoTitle.png'
